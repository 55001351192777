$jHeadingFont: "Rubik", sans-serif;
$jBodyFont: "Manrope", sans-serif;
$jPrimaryColor: #009291;
$jPrimaryColor2: #246F6B;
$jSecondaryColor: #A89060;
$jSecondaryDark: #9a7d44;
$jPrimaryColorDark: #03343B;
$jPinkColor: #CF035C;
$jWhiteColor: #fff;
$jFW600: 600;
$jPrimaryLight: #E2EDF1;

html,
body,
ul,
ol {
    margin: 0;
    padding: 0;
}
body {
    font-family: $jBodyFont;
    font-size: 16px;
    line-height: 140%;
    letter-spacing: 0.3px;
    overflow-x: hidden;
    top:0 !important;
}
font{ background: none !important; box-shadow: none !important;}
.translated-ltr {
    .p-enquiry{ font-size: 10px !important;}
    .process-flow .feature-box p, .feature-box p{ font-size: 13px !important;}
}
h1,
h2,
h3,
h4,
h5,
h6 {
    font-family: $jHeadingFont;
    font-weight: $jFW600;
    text-transform: uppercase;
}

a {
    text-decoration: none;
}

p {
    font-size: 16px;
    line-height: 24px;
}

.running-text {
    line-height: 30px;
}

.dropdown-toggle::after {
    display: none;
}
.dropdown-toggle .iconify{ margin-top: -5px; font-size: 14px; }

.btn {
    border-radius: 0px;
    padding: 8px 30px;
    font-size: 14px;
    border-width: 2px;
    font-weight: 600;
    text-transform: uppercase;
}

.btns {
    margin: 0 -8px;
}

.btns .btn {
    margin: 0 8px;
}

.btn-primary {
    background-color: $jSecondaryColor;
    border-color: $jSecondaryColor;
}

.btn-primary:hover, .btn-primary:active, .btn:first-child:active{
    background-color: $jSecondaryDark !important;
    border-color: $jSecondaryDark !important;
}

.btn-outline.dark {
    color: $jPrimaryColorDark;
    border-color: $jPrimaryColorDark;
}

.btn-outline {
    color: $jWhiteColor;
    border-color: $jWhiteColor;
}

.btn-outline:hover {
    color: $jWhiteColor;
    border-color: $jSecondaryDark;
    background-color: $jSecondaryDark;
}

header.fixed {
    position: fixed;
    top: 0;
    z-index: 1000;
    right: 0;
    background: #fff;
    left: 0;

    .header-top {
        display: none;
    }
}

.header-top {
    background-color: $jPrimaryColorDark;
    color: $jWhiteColor;
    padding: 5px 0;
    text-transform: uppercase;
    .row {
        align-items: center;
    }
}

.header-top p {
    margin-bottom: 0;
    font-size: 13px;
}

.navbar-brand {
    width: 120px;
}

.language {
    float: right;
    position: relative;
    padding-left: 30px;

    li {
        display: inline-block;
        color: $jWhiteColor;
        font-size: 13px;
        margin-left: 5px;
        padding: 3px 15px;
        cursor: pointer;
    }li.active {
        background-color: $jSecondaryColor;
        color: $jWhiteColor !important;
    }

    li:hover {
        color: $jSecondaryColor;
    }

    .iconify {
        position: absolute;
        left: 5px;
        top: 5px;
    }
}

.carousel-indicators {
    bottom: 30px;
}

.banner-caption {
    text-align: center;
    color: $jWhiteColor;
    text-transform: uppercase;
    z-index: 1;
    position: relative;

    h2 {
        font-size: 80px;
    }

    p {
        font-size: 26px;
        font-weight: 600;
    }
}

.banner-bg {
    padding: 180px 0;
    position: relative;
}

.banner-bg:after {
    position: absolute;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0px;
    content: "";
    z-index: 0;
    background: rgb(0, 0, 0);
    background: -moz-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.15962881734725143) 75%);
    background: -webkit-linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.15962881734725143) 75%);
    background: linear-gradient(0deg, rgba(0, 0, 0, 1) 0%, rgba(0, 0, 0, 0.15962881734725143) 75%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#000000", endColorstr="#000000", GradientType=1);
}

.banner-bg.bbg1 {
    background: url(../public/assets/images/banner-1.jpg) no-repeat center center/cover $jPrimaryColorDark;
}

.banner-bg.bbg2 {
    background: url(../public/assets/images/banner-2.jpg) no-repeat center center/cover $jPrimaryColorDark;
}

.enquiry-link {
    font-size: 11px;
    color: $jPrimaryColorDark;
    position: relative;
    // padding-left: 45px;
    text-transform: uppercase;
    display: block;

    label {
        font-size: 18px;
        display: block;
        font-weight: 800;
        cursor: pointer;
        line-height: 22px;
        margin-top: -2px;
    }

    img {
        position: absolute;
        left: -47px;
    }
}

.nav-link {
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 600;
    color: $jPrimaryColorDark;
    padding: 5px 15px !important;
    margin: 0 5px;
}

.nav-link.active,
.nav-link:hover {
    background-color: $jSecondaryColor !important;
    color: $jWhiteColor !important;
}

.navbar-toggler,
.navbar-toggler:focus {
    border: none;
    color: $jPrimaryColorDark;
    box-shadow: none;
    line-height: 30px;
}

.navbar-toggler-icon {
    background-image: none;
}

.navbar-toggler-icon::before {
    font-family: FontAwesome;
    content: "\f0c9";
}

.dropdown:hover .dropdown-menu {
    display: block;
    margin-top: 0;

}

.navbar-nav {
    .dropdown-menu {
        padding: 0;
        border: 0;
        border-radius: 0;
        left: 5px;
        min-width: 200px;
    }

    .dropdown-item {
        text-transform: uppercase;
        font-size: 13px;
        font-weight: 600;
        padding: 10px 15px;
        color: $jPrimaryColorDark;

        span img {
            width: 25px;
            margin-right: 10px;
        }
    }

    .dropdown-item:hover {
        background-color: $jSecondaryColor;
        color: $jWhiteColor;
    }
}

.section {
    padding: 60px 0;
}

.section.inner {
    padding: 60px 0;
}

.heading {
    h2 {
        font-size: 44px;
        color: $jPrimaryColorDark;
        text-transform: uppercase;
        margin-bottom: 10px;
    }
}

.about-cont {
    h2 {
        font-size: 44px;
        color: $jPrimaryColorDark;

        span {
            color: $jPrimaryColorDark;
        }
    }

    h5 {
        font-size: 28px;
        color: $jPrimaryColorDark;
    }
}

.about-img {
    position: relative;

    img {
        margin-bottom: 30px;
    }

    .img2 {
        position: absolute;
        right: -55px;
        bottom: -65px;
        max-width: 150px;
        border: 8px solid $jWhiteColor;
        z-index: 9;
    }
}

.feature-box {
    position: relative;
    padding: 40px 30px;
    background-color: $jPrimaryColor;
    color: $jWhiteColor;

    p {
        font-size: 14px;
        text-transform: uppercase;
        font-weight: 800;
        line-height: 20px;
        margin: 0 0 20px 0;
    }

    .iconify {
        font-size: 50px;
        -webkit-transition: all .2s ease-in-out;
        -moz-transition: all .2s ease-in-out;
        -ms-transition: all .2s ease-in-out;
        -o-transition: all .2s ease-in-out;
        transition: all .2s ease-in-out;
    }
}

.feature-box:hover,
.feature-box.alt:hover {
    background-color: $jPrimaryColor2;
    color: $jWhiteColor;

    .iconify {
        -moz-transform: translateX(100px);
        -webkit-transform: translateX(100px);
        -o-transform: translateX(100px);
        -ms-transform: translateX(100px);
        transform: translateX(100px);
    }
}

.feature-box.alt {
    background-color: $jPrimaryLight;
    color: $jPrimaryColor2;

    p {
        margin: 0;
    }

    .iconify {
        margin-bottom: 20px;
    }
}

.process-flow {
    .feature-box {
        text-align: center;
        padding: 40px 20px;
        position: relative;

        .icon {
            position: absolute;
            top: 17px;
            right: 10px;
            // width: 30px;
            // height: 30px;
            // line-height: 37px;
            // border-radius: 50%;
            // text-align: center;
            // background: $jSecondaryDark;
            color: $jPrimaryColorDark;
            z-index: 9;
            .iconify {
                font-size: 28px;
                color: $jPrimaryColor;
            }
            &:hover {
                .iconify {
                    color: $jWhiteColor !important;
                }                
            }
        }

        span {
            position: absolute;
            left: 20px;
            top: 20px;
            font-size: 26px;
            font-weight: 800;
        }

        .iconify {
            font-size: 60px;
            margin-bottom: 10px;
            transform: none;

        }

        p {
            margin: 0;
            font-size: 16px !important;
        }
        &:hover {
            .iconify {
                color: $jWhiteColor !important;
            }   
        }
    }

    .feature-box.alt {
        .iconify {
            transform: none;
        }
    }

    .feature-box:hover .iconify {
        -moz-transform: rotate(360deg);
        -webkit-transform: rotate(360deg);
        -o-transform: rotate(360deg);
        -ms-transform: rotate(360deg);
        transform: rotate(360deg);
    }
}

.shipping-desc {
    max-width: 50%;
    margin: 30px auto;
    text-align: center;
    p {
        font-size: 16px;
    }
}

.owl-theme .owl-dots {
    margin-top: 30px !important;
}

.product {
    background-color: $jWhiteColor;
    padding: 15px;
    position: relative;
    border: 1px solid $jPrimaryLight;

    .p-img {
        text-align: center;
        position: relative;

        img {
            max-width: 100%;
            -webkit-transition: all 0.2s ease-in-out;
            -moz-transition: all 0.2s ease-in-out;
            -ms-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
        }

        .img-zoom {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            text-align: center;
            opacity: 0;
            -moz-transform: scale(0.1);
            -webkit-transform: scale(0.1);
            -o-transform: scale(0.1);
            -ms-transform: scale(0.1);
            transform: scale(0.1);
            -moz-transition: all 0.2s ease-in-out;
            -ms-transition: all 0.2s ease-in-out;
            -o-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;

            .iconify {
                font-size: 30px;
                color: $jWhiteColor;
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }
    }

    .p-img:hover .img-zoom {
        opacity: 1;
        -moz-transform: scale(1);
        -webkit-transform: scale(1);
        -o-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
    }

    .modal-no {
        text-transform: uppercase;
        font-weight: 800;
        font-size: 16px;
        margin: 0;
        line-height: 18px;

        span {
            display: block;
            font-size: 12px;
            font-weight: 400;
            color: #666666;
        }
    }

    .p-enquiry {
        position: absolute;
        right: 15px;
        font-size: 13px;
        bottom: 15px;
        font-weight: 800;
        color: $jSecondaryDark;
        text-transform: uppercase;

        img {
            width: 25px !important;
            display: inline-block !important;
        }
        .iconify{ font-size: 20px; margin-top: -2px;}

        span {
            margin-left: 5px;
        }
    }
}

.product:hover .p-img img {
    -moz-transform: scale(0.9);
    -webkit-transform: scale(0.9);
    -o-transform: scale(0.9);
    -ms-transform: scale(0.9);
    transform: scale(0.9);

}

.alt-bg {
    background-color: $jPrimaryLight;
}

.dark-bg {
    background-color: $jPrimaryColorDark;
}

.rating {
    .iconify {
        display: inline-block;
        margin: 0 3px;
        color: $jSecondaryColor;
    }
}

.testimonial {
    position: relative;
    padding: 30px;
    height: 100%;
    background: $jWhiteColor;

    p {
        color: #000;
        margin: 15px 0 30px;
        font-size: 13px;
        line-height: 20px;
    }

    border-radius: 8px;
    box-shadow: 0px 2px 18px 0px rgba(0, 0, 0, 0.09);

    .client-info {
        position: relative;
        padding-left: 55px;
        img {
            position: absolute;
            left: 0;
            top: 0;
            width: 45px !important;
            border-radius: 50%;
            border: 3px solid $jPrimaryLight;
        }

        .c-name {
            font-size: 18px;
            color: $jPrimaryColorDark;
            margin: 0;            
        }

        .designation {
            font-size: 14px;
            color: $jSecondaryDark;
            margin: 0;
            position: relative;
            top: 13px;
        }
    }
}

.materials-list {
    display: flex;
    width: 100%;
    padding: 0px;
    box-sizing: border-box;
    height: 400px;

    .box {
        flex: 1;
        overflow: hidden;
        transition: .5s;
        margin: 0 8px;
        box-shadow: 0 20px 30px rgba(0, 0, 0, .1);
        line-height: 0;
        position: relative;
    }

    .box>img {
        width: 200%;
        height: 100%;
        object-fit: cover;
        transition: .5s;
    }

    .box>span {
        font-size: 16px;
        font-weight: 800;
        text-transform: uppercase;
        color: $jWhiteColor;
        position: absolute;
        right: 0px;
        left: 0;
        text-align: center;
        bottom: 30px;
        z-index: 9;
        line-height: 20px;
    }

    .box:hover {
        flex: 1 1 15%;
    }

    .box:hover>img {
        width: 100%;
        height: 100%;
    }
}

footer {
    padding: 60px 0 50px 0;

    .footer-widget {
        color: $jWhiteColor;

        a:hover {
            color: $jSecondaryColor;
        }

        .footer-logo img {
            max-width: 120px;
        }

        h2 {
            font-size: 20px;
            text-transform: uppercase;
            margin-bottom: 30px;
        }

        p {
            margin: 30px 0;
        }

        .readmore {
            font-weight: 600;
            color: $jWhiteColor;
        }

        ul {
            padding: 0;
            list-style: none;

            a {
                color: $jWhiteColor;
                display: block;
                margin-bottom: 10px;
            }

        }

        .contact-info {
            padding-left: 30px;
            position: relative;

            a {
                color: $jWhiteColor;
            }

            a:hover {
                color: $jSecondaryColor !important;
            }

            span {
                position: absolute;
                left: 0;
                top: -1px;
                font-size: 20px;
            }
        }
        &.footer-logo {
            p {
                margin-bottom: 10px;
            }
        }
    }

    .enquiry-link {
        color: $jWhiteColor;
    }

    .copyright {
        padding: 50px 0 0;
        margin-top: 50px;
        text-align: center;
        color: $jWhiteColor;
        border-top: 1px solid rgba(255, 255, 255, 0.5);

        p {
            font-size: 16px;
        }
    }
    .association-logo {
        margin-top: 20px;
        p {
            font-size: 16px; 
            font-weight: 600;
            color: $jWhiteColor;
            margin: 0 0 10px;
            text-transform: uppercase;
        }
        ul {
            padding: 0;
            margin: 0;
            li {
                list-style: none;
                display: inline-block;
                margin-right: 15px;
                img {
                    height: 80px;
                    filter: brightness(0) invert(1);
                }
            }
        }
    }
}

.social-icons {
    margin-top: 20px;
    ul {
        padding: 0;
        margin: 0;
        li {
            list-style: none;
            display: inline-block;
            margin-right: 8px;
            a {
                width: 30px;
                height: 30px;
                line-height: 25px;
                font-size: 18px;
                border-radius: 50%;
                text-align: center;
                border:1px solid $jWhiteColor;
                color: $jWhiteColor;    
                &:hover, &:focus {
                    border:1px solid $jSecondaryDark;
                }
            }
        }
    }
}

.scroll-to-top {
    border-radius: 50% !important;
    font-size: 26px;
    right: 30px !important;
    bottom: 100px !important;
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
    background: $jPrimaryColor2 !important;
}

.owl-theme .owl-dots .owl-dot span {
    width: 12px !important;
    height: 12px !important;
}

.owl-carousel .owl-stage {
    display: flex;
}

/* Shine */
.hovershine figure {
    position: relative;
}

.hovershine figure::before {
    position: absolute;
    top: 0;
    left: -75%;
    z-index: 2;
    display: block;
    content: '';
    width: 50%;
    height: 100%;
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, .3) 100%);
    -webkit-transform: skewX(-25deg);
    transform: skewX(-25deg);
}

.hovershine {
    position: relative;
    width: 100%;
}

.hovershine span {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    z-index: 3;
    display: block;
    margin: 0;
    padding: 0;
    color: #fff;
    font-size: 14px;
    text-decoration: none;
    text-align: center;
    -webkit-transition: .3s ease-in-out;
    transition: .3s ease-in-out;
    text-transform: uppercase;
    font-weight: 800;
    text-shadow: 0 0 3px rgba(0, 0, 0, 0.5);
}

.hovershine figure {
    margin: 0;
    padding: 0;
    background: #fff;
    overflow: hidden;
}

.hovershine:hover span {
    bottom: 45%;
    opacity: 1;
}

.hovershine figure:hover::before {
    -webkit-animation: shine .75s;
    animation: shine .75s;
}

@-webkit-keyframes shine {
    100% {
        left: 125%;
    }
}

@keyframes shine {
    100% {
        left: 125%;
    }
}

.accordion-button {
    font-size: 16px;
    padding: 25px;
    font-weight: 600;
    text-transform: uppercase;
}

.accordion-button span {
    font-size: 30px;
    width: 40px;
}

.accordion-body {
    padding: 25px;
}

.accordion-button:not(.collapsed) {
    background-color: $jPrimaryLight !important;
}

.accordion-button:focus {
    box-shadow: none;
}

.contact-pageinfo {
    background-color: $jWhiteColor;
    padding: 50px;
    position: relative;
    border-style: dashed;
    border-width: 8px;
    border-image-source: url(../public/assets/images/icons/grey-pattern.png);
    border-image-repeat: repeat;
    border-image-slice: 8;

    a {
        color: $jSecondaryDark;
    }

    .iconify {
        color: $jSecondaryColor;
        display: none;
    }
}

.border-design {
    background-color: $jWhiteColor;
    position: relative;
    border-style: dashed;
    border-width: 8px;
    border-image-source: url(../public/assets/images/icons/grey-pattern.png);
    border-image-repeat: repeat;
    border-image-slice: 8;
}

.form-group {
    position: relative;

    .form-label {
        margin-bottom: 5px;
        font-size: 14px;
        text-transform: uppercase;
    }

    .form-control,
    .form-select {
        border-radius: 0;
        background-color: $jPrimaryLight;
        font-size: 14px;
        padding: 12px 20px;
    }

    .form-control:focus,
    .form-select:focus {
        box-shadow: none;
        outline: none;
    }
    .form-control {
        &.is-invalid{
            border-color: var(--bs-form-invalid-border-color)!important;
            background-image:url("data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e")!important;
            &:focus{
                box-shadow:0 0 0 .25rem rgba(var(--bs-danger-rgb),.25)!important;
            }
        }
    }
}
.form-label .req{ color: $jPinkColor; font-size: 16px;}

.alert {
    border-radius: 0;
    margin-top: 15px;
    text-align: center;
}

.invalid-feedback {
    position: absolute;
    font-size: 12px;
    right: 10px;
    top: 0;
    margin: 0;
    width: auto;
}

@media (max-width: 1199px) {
    .nav-link {
        padding: 5px 10px !important;
        font-size: 13px;
    }

    .navbar-brand {
        width: 120px;
    }

    .banner-caption {
        h2 {
            font-size: 60px;
        }

        p {
            font-size: 16px;
        }
    }

    .p-enquiry {
        span {
            display: none;
        }
    }

    footer .footer-widget h2 {
        font-size: 18px;
    }

    .materials-list .box {
        margin: 0 2px;
    }
}

@media (max-width: 767px) {
    .banner-bg{ padding: 80px 0; }
    .banner-caption h2 {
        font-size: 40px;
    }
    .process-flow .feature-box span {
        left: 15px;
        top: 15px;
    }

    .footer-widget {
        margin-bottom: 50px;
    }

    .contact-pageinfo {
        padding: 15px;
    }

    .hovershine span {
        font-size: 12px;
    }

    .accordion-button {
        padding: 20px;
        font-size: 14px;
    }

    .accordion-button span {
        display: none;
    }

    .about-img .img2 {
        display: none;
    }

    .section {
        padding: 50px 0;
    }

    .section.inner {
        padding: 50px 0px 30px;
    }

    .contact-pageinfo.formtablet, .contactForm.formtablet {
        .fs-webform-container {
            .fserv-container {
                box-shadow: none !important;
                padding-top: 0 !important;
                padding-bottom: 0;
                margin: 0;
                max-width: 100% !important;
                form {
                    padding-top: 0 !important;
                }           
                .fserv-form-name {
                    text-align: left !important;
                    margin: 0 !important;
                    padding: 0 !important;
                    font-size: 1.25rem !important;
                }
    
                .fserv-form {                
                    margin: 0 !important;
                    .fserv-field {  
                        .fserv-label {
                            margin: 0 0 5px;
                            padding: 0;
                        }
                        width: 100% !important;                       
                        margin: 10px 0;                                        
                    } 
                    .fserv-field:nth-child(5) {
                        width: 100% !important;
                    }
                    .fserv-button-submit {
                        position: relative;
                        right: -25px !important;
                    }
                }
    
            }
            
        }
    
        div.mt-5 {
            margin-top: 23px !important;
        }
    }

    .navbar-brand {
        width: 90px;
    }

    .navbar-nav .dropdown-item {
        padding: 5px 25px;
        font-size: 12px;
    }

    .enquiry-link.mob {
        position: absolute;
        left: 110px;
        top: 12px;
    }

    .enquiry-link label {
        font-size: 14px;
    }

    .enquiry-link img {
        width: 30px;
        left: 5px;
        top: 4px;
    }

    .navigation-links {
        padding: 15px 0;
    }

    .feature-box {
        padding: 15px;
        margin-top: 15px;

        p {
            font-size: 12px;
        }
    }

    .about-img {
        .img2 {
            display: none;
        }
    }

    .heading h2,
    .about-cont h2 {
        font-size: 30px;
    }
    .direction-arrow {
        display: none !important;
    }

    .about-cont h5 {
        font-size: 20px;
    }

    p,
    footer .footer-widget ul a,
    footer .copyright p,
    footer .footer-widget .readmore {
        font-size: 14px;
    }

    .materials-list {
        display: block;
        height: auto;

        .box {
            margin-bottom: 15px;
            height: 150px;
        }
    }

    // new
    .heading {
        p {
            br {
                display: none;
            }
        }
    }
    .shipping-desc {
        max-width: 90%;
        margin-bottom: 0;
        p {
            margin-bottom: 0;
        }
    }
    .social-icons ul li {
        margin-right: 5px;
        a {
            width: 25px;
            height: 25px;
            line-height: 20px;
        }
    }
    .contact-pageinfo.border-0 {
        border: none;
        padding: 0;
    }
    
    // .fs-country-phone::part(fw-select-input-container) {        
    //     width: 100px !important;
    // }

}

// Tablet Landscape
@media (min-width: 992px) and (max-width: 1199px) {
    .contact-pageinfo.formtablet, .contactForm.formtablet {
        .fs-webform-container {
            .fserv-container {
                box-shadow: none !important;
                padding-top: 0 !important;
                padding-bottom: 0;
                margin: 0;
                max-width: 100% !important;
                form {
                    padding-top: 0 !important;
                }           
                .fserv-form-name {
                    text-align: left !important;
                    margin: 0 !important;
                    padding: 0 !important;
                    font-size: 1.25rem !important;
                }
    
                .fserv-form {                
                    margin: 0 -10px !important;
                    .fserv-field {  
                        .fserv-label {
                            margin: 0 0 5px;
                            padding: 0;
                        }
                        width: 100% !important;                       
                        margin: 10px 10px;                                        
                    } 
                    .fserv-field:nth-child(5) {
                        width: 100% !important;
                    }
                    .fserv-button-submit {
                        position: relative;
                        right: -35px;
                    }
                }
    
            }
            
        }
    
        div.mt-5 {
            margin-top: 23px !important;
        }
    }
}

@media (max-width: 320px) {
    .enquiry-link.mob {
        display: none !important;
    }
}

.blink {
    animation: blink-animation 1s steps(5, start) infinite;
    -webkit-animation: blink-animation 1s steps(5, start) infinite;
}

@keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

@-webkit-keyframes blink-animation {
    to {
        visibility: hidden;
    }
}

.sample-link{ color: $jSecondaryDark; text-transform: uppercase; font-weight: 800; display: block;}
.sample-link .iconify{ font-size: 20px;  margin-top: -3px;  margin-right: 3px;}
.PinkText{ color: $jPinkColor;}
.icon-widget{ margin-top: 20px;}
.icon-widget .icon{ font-size: 40px; color: $jSecondaryDark; margin-bottom: 10px; display: inline-block;}
.why-widget{ position: relative; padding-left: 60px; margin-top: 30px;}
.why-widget .icon{ position: absolute; top: 0; left: 0; width: 50px; height: 50px; padding: 10px; text-align: center; border-radius: 50%; background: $jSecondaryDark; color: #fff; font-size: 30px; }
.why-widget h3{ font-size: 16px; font-weight: 600; margin-bottom: 5px;}
.why-widget p{ margin: 0; font-size: 15px; }
.consultation{ padding: 100px 0; text-align: center; color: #fff; background: url(../public/assets/images/consultation-bg.jpg) no-repeat center center; position: relative;}
.consultation::after{ position: absolute; content: ""; right:0; top: 0; left: 0; bottom: 0; background:rgba(3,52,59,0.9);}
.z-index{ z-index: 99; position: relative;}
.relative{ position: relative;}
.heading.light h2{ color: $jWhiteColor !important;}
// .material-slide .hovershine{ border-radius: 50%; overflow: hidden;}

.direction-arrow{ width: 120px; height: 120px; display: block; position: absolute; right: 0; top: -30px; background: url(../public/assets/images/icons/arrow.svg) no-repeat center center; background-size: 100%; }
.country_tabs .nav-item .nav-link{ text-align: center; padding: 10px !important; border-radius: 50px 50px 0 0;}
.country_tabs .nav-item .nav-link span img{ width: 60px; display: block; margin: 0 auto 5px; }
.country_tabs .nav-item .nav-link span { font-size: 16px;}
.country_tabs .nav-item .nav-link span label{ display: block; font-weight: 600; font-size: 12px;}
.country_tabs li {width: 100px;}

.fs-webform-container .powered-by {display: none !important;}
.contact-pageinfo, .contactForm {
    .fs-webform-container {
        .fserv-container {
            box-shadow: none !important;
            padding-top: 0 !important;
            padding-bottom: 0;
            margin: 0;
            max-width: 100% !important;
            form {
                padding-top: 0 !important;
            }           
            .fserv-form-name {
                text-align: left !important;
                margin: 0 !important;
                padding: 0 !important;
                font-size: 1.25rem !important;
            }

            .fserv-form {                
                margin: 0 -10px !important;
                .fserv-field {                    
                    padding: 0;
                    margin: 0 0 25px;
                    position: relative;
                    .fserv-label {
                        margin: 0 0 5px;
                        padding: 0;
                        text-transform: uppercase;
                        font-family: $jBodyFont !important;
                    }
                    // width: 46% !important;
                    // float: left;
                    margin: 10px 10px;
                    & .fserv-input-text{
                        background-color: #e2edf1 !important;
                        border-radius: 0;
                        font-size: 14px;
                        padding: 12px 20px;
                    }                    
                } 
                .fserv-field label.error {
                    position: absolute;
                    top: -10px;
                    right: -15px;
                }
                .fserv-field:nth-child(5) {
                    width: 96% !important;
                    // float: inherit !important;
                }
                .fserv-button-submit {
                    position: relative;
                    right: -14px;
                    top: 15px;
                    text-transform: uppercase;
                }
            }

        }
        .fserv-field + div:not(.fserv-field) {
            border-top: none !important;
        }
        
    }

    div.mt-5 {
        margin-top: 23px !important;
    }
}

.fs-webform-container .fserv-field + div:not(.fserv-field) {
    margin: 0 !important;
    padding: 0 !important;
    text-align: left !important;
}
.fs-webform-container .fserv-container button[type="submit"] {
    margin-left: -5px !important;
}


// .fs-country-phone::part(fw-select-input-container) {
//     background-color: #e2edf1 !important;
//     border-radius: 0;
//     width: 120px !important;
// }

// .fs-country-phone::part(field-control) input{
//     background-color: #e2edf1 !important;
//     background: #e2edf1 !important;
// }


.aboutSlider {
    .carousel-indicators {
        bottom: -20px;        
        [data-bs-target] {
            background-color: $jPrimaryColorDark;
        }
    }
}